// Элемент опции сортировки

import React from 'react';
import PropTypes from 'prop-types';
import MarkIcon from '../icons/MarkIcon';

function SortOptionItem({ label, value, isSelected, onClick }) {
  return (
    <li className="mb-2">
      <button
        onClick={() => onClick(value)}
        className="flex items-center w-full text-left"
      >
        <span className="mr-2 w-4 h-4 flex items-center justify-center">
          {isSelected && (
            <MarkIcon className="w-full h-full text-psl-main-accent-light dark:text-psd-main-accent-dark" />
          )}
        </span>
        <span className="text-psl-main-text-light dark:text-psd-main-text-dark">
          {label}
        </span>
      </button>
    </li>
  );
}

SortOptionItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SortOptionItem;
