// Управляет таймером обновления

import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { authConfig } from './authConfig';
import { authError } from './authSlice';
import { useTokenRefresh } from './useTokenRefresh';

function useTokenExpirationTimer(accessToken) {
  const dispatch = useDispatch();
  const { tokens } = useSelector((state) => state.auth);
  const { refreshToken } = useTokenRefresh();

  const calculateRefreshTime = useCallback((token) => {
    try {
      const decodedToken = jwtDecode(token);
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      const timeUntilExpiration = expirationTime - currentTime;
      
      return {
        refreshTime: timeUntilExpiration - authConfig.tokenRefreshOffset,
        isValid: true
      };
    } catch (error) {
      dispatch(authError({
        code: null,
        message: 'Invalid token format'
      }));
      return {
        refreshTime: 0,
        isValid: false
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (!accessToken || !tokens.refreshToken) {
      return;
    }

    const { refreshTime, isValid } = calculateRefreshTime(accessToken);
    let timerId = null;

    if (isValid) {
      if (refreshTime > 0) {
        timerId = setTimeout(() => {
          refreshToken(tokens.refreshToken);
        }, refreshTime);
      } else {
        refreshToken(tokens.refreshToken);
      }
    }

    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [accessToken, tokens.refreshToken, calculateRefreshTime, refreshToken]);
}

export default useTokenExpirationTimer;