// Cтатичные данные для фейк страницы

const staticPosts = [
  {
    id: 1,
    title: 'Влияние правильного питания на ваше здоровье',
    description: 'Правильное питание играет ключевую роль в поддержании здоровья и предотвращении заболеваний.',
    tags: ['Питание', 'Здоровье и медицина', 'Самореализация', 'Статья'],
    content: ''
  },
  {
    id: 2,
    title: 'Как кофе влияет на нашу продуктивность',
    description: 'Исследования показывают, что кофе стимулирует мозговую активность и может улучшить концентрацию.',
    tags: ['Питание', 'Продуктивность', 'Здоровье и медицина', 'Видео'],
    content: ''
  },
  {
    id: 3,
    title: 'Основы медитации для начинающих',
    description: 'Медитация помогает улучшить психическое здоровье и укрепить эмоциональную устойчивость.',
    tags: ['Психическое здоровье', 'Медитация', 'Самореализация', 'Стрим'],
    content: ''
  },
  {
    id: 4,
    title: 'Сила сна: как наладить режим',
    description: 'Сон — это важнейший элемент здоровья, который влияет на ваше физическое и психическое состояние.',
    tags: ['Здоровье и медицина', 'Сон', 'Продуктивность', 'Статья'],
    content: ''
  },
  {
    id: 5,
    title: 'Интервальное голодание: преимущества и недостатки',
    description: 'Интервальное голодание становится всё более популярным методом для поддержания здоровья и снижения веса.',
    tags: ['Питание', 'Здоровье и медицина', 'Статья'],
    content: ''
  },
  {
    id: 6,
    title: 'Стратегии для повышения продуктивности',
    description: 'Как организовать своё время и рабочее пространство для максимальной эффективности.',
    tags: ['Продуктивность', 'Работа', 'Видео'],
    content: ''
  },
  {
    id: 7,
    title: 'Как укрепить иммунитет зимой',
    description: 'Зима — это период, когда наш организм особенно уязвим к вирусам и простудам.',
    tags: ['Здоровье и медицина', 'Семья', 'Стрим'],
    content: ''
  },
  {
    id: 8,
    title: 'Как вода влияет на здоровье организма',
    description: 'Вода необходима для поддержания всех жизненно важных функций организма.',
    tags: ['Здоровье и медицина', 'Питание', 'Видео'],
    content: ''
  },
  {
    id: 9,
    title: 'Советы по эффективным переговорам',
    description: 'Умение вести переговоры — это важный навык как в деловой, так и в личной жизни.',
    tags: ['Психическое здоровье', 'Коммуникация', 'Продуктивность', 'Стрим'],
    content: ''
  },
  {
    id: 10,
    title: 'Роль сахара в рационе и его влияние на здоровье',
    description: 'Чрезмерное употребление сахара может привести к негативным последствиям для здоровья.',
    tags: ['Питание', 'Здоровье и медицина', 'Видео'],
    content: ''
  },
  {
    id: 11,
    title: 'Как научиться быстро принимать решения',
    description: 'Принятие быстрых решений — это навык, который можно развить с помощью практики и самодисциплины.',
    tags: ['Продуктивность', 'Самореализация', 'Стрим'],
    content: ''
  },
  {
    id: 12,
    title: 'Значение физической активности для здоровья',
    description: 'Регулярная физическая активность способствует укреплению иммунной системы и улучшению общего самочувствия.',
    tags: ['Здоровье и медицина', 'Спорт', 'Самореализация', 'Видео'],
    content: ''
  },
  {
    id: 13,
    title: 'Почему важно учиться на ошибках',
    description: 'Ошибки — это неотъемлемая часть процесса обучения и развития.',
    tags: ['Самореализация', 'Психическое здоровье', 'Статья'],
    content: ''
  },
  {
    id: 14,
    title: 'Как поддерживать баланс между работой и личной жизнью',
    description: 'Баланс между работой и личной жизнью — это ключ к психическому и физическому здоровью.',
    tags: ['Психическое здоровье', 'Продуктивность', 'Здоровье и медицина', 'Статья'],
    content: ''
  },
  {
    id: 15,
    title: 'Преимущества осознанного дыхания для здоровья',
    description: 'Осознанное дыхание — это простая практика, которая может улучшить ваше психическое и физическое здоровье.',
    tags: ['Медитация', 'Психическое здоровье', 'Здоровье и медицина', 'Видео'],
    content: ''
  }
    ];
   
  
  export default staticPosts;
  