// Маленькая кнопка для навигации в хедере

import React from 'react';
import PropTypes from 'prop-types';

function SmallButton({ icon, ariaLabel, onClick }) {
  return (
    <button
      onClick={onClick}
      aria-label={ariaLabel}
      className="p-2 rounded-full focus:outline-none"
    >
      {icon}
    </button>
  );
}

SmallButton.propTypes = {
  icon: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SmallButton;
