// Параметры аутентификации

export const authConfig = {
    // Время жизни access_token
    tokenLifetime: 15 * 60 * 1000, // 15 минут
    // За сколько времени нужно инициировать обновление
    tokenRefreshOffset: 2 * 60 * 1000, // 2 минуты
    // Статус-коды для обработки auth
    statusCodes: {
      success: 200,
      noSubscription: 403,
      invalidInitData: 400,
      tooManyRequests: 429
    }
  };