import { useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectSearchQuery, 
  selectSelectedTags, 
  selectSelectedSortOrder 
} from './searchParamsSlice';
import { setPage } from '../posts/postsSlice';

function useSearchParams(fetchPosts) {
  const dispatch = useDispatch();
  const searchQuery = useSelector(selectSearchQuery);
  const selectedTags = useSelector(selectSelectedTags);
  const selectedSort = useSelector(selectSelectedSortOrder);
  const isInitialMount = useRef(true);
  const isSearching = useRef(false);
  
  const prevSearchParams = useRef({
    searchQuery: '',
    selectedTags: [],
    selectedSort: 'desc'
  });

  const currentSearchParams = useMemo(() => ({
    searchQuery,
    selectedTags,
    selectedSort
  }), [searchQuery, selectedTags, selectedSort]);

  useEffect(() => {
    if (isInitialMount.current) {
      console.log('useSearchParams: Skipping initial search query effect');
      isInitialMount.current = false;
      return;
    }

    const hasSearchChanged =
      searchQuery !== prevSearchParams.current.searchQuery ||
      selectedTags !== prevSearchParams.current.selectedTags ||
      selectedSort !== prevSearchParams.current.selectedSort;

    if (hasSearchChanged) {
      console.log('useSearchParams: Search parameters changed');
      prevSearchParams.current = currentSearchParams;

      window.scrollTo({ top: 0, behavior: 'smooth' });

      dispatch(setPage(1));
      fetchPosts(1, true);
    } else {
      console.log('useSearchParams: Search parameters unchanged, skipping reset');
    }
  }, [searchQuery, selectedTags, selectedSort, fetchPosts, dispatch, currentSearchParams]);

  return { isSearching: isSearching.current };
}

export default useSearchParams;