// Страница первой подписки пользователя

import React from 'react';
import AppModal from '../components/common/AppModal';
import FakeKnowledgeBasePage from '../components/common/FakeKnowledgeBasePage';
import BlurOverlay from '../components/common/BlurOverlay';

function SubscriptionPage() {
  const handleSubscribe = () => {
    window.location.href = "https://go.prosto.social/#rec808884693";
  };
  
  return (
    <div className="relative min-h-screen">
      <FakeKnowledgeBasePage />
      <BlurOverlay />
      <AppModal
        emoji="👋"
        title="Ещё один шаг"
        description="Вас ждут:"
        benefits={[
          <span key="benefit-1">
            <strong>350+</strong> материалов базы знаний
          </span>,
          <span key="benefit-2">
            <strong>4 стрима</strong> каждый месяц
          </span>,
          <span key="benefit-3">
            <strong>2000+</strong> участников коммьюнити
          </span>,
        ]}
        buttonText="Получить доступ"
        onButtonClick={handleSubscribe}
      />
    </div>
  );
}

export default SubscriptionPage;
