// API получения конкретного поста

import axios from 'axios';
import { apiConfig } from './apiConfig';

export const getFullPost = async (accessToken, articleId) => {
  const response = await axios.post(
    `${apiConfig.apiBaseURL}${apiConfig.endpoints.articles.get}`,
    {
      article_id: articleId
    },
    {
      headers: {
        auth: accessToken
      }
    }
  );
  return response.data;
};