import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'idle',
  currentPost: null,
  error: null
};

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    fetchPostStart: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    
    fetchPostSuccess: (state, action) => {
      state.status = 'succeeded';
      state.currentPost = {
        id: action.payload.article_id,
        title: action.payload.article_name,
        description: action.payload.article_short_description,
        content: action.payload.article_text,
        videoUrl: action.payload.video_url,
        type: action.payload.type,
        createdAt: action.payload.article_created_at,
        chat: action.payload.chat,
        tags: action.payload.tags
      };
      state.error = null;
    },
    
    fetchPostFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
      state.currentPost = null;
    },
    
    resetPost: () => initialState
  }
});

export const {
  fetchPostStart,
  fetchPostSuccess,
  fetchPostFailure,
  resetPost
} = postSlice.actions;

export const selectCurrentPost = (state) => state.post.currentPost;
export const selectPostStatus = (state) => state.post.status;
export const selectPostError = (state) => state.post.error;

export default postSlice.reducer;