import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedTag, removeSelectedTag, selectSelectedTags } from '../../../features/tags/tagsSlice';
import { setSelectedTags } from '../../../features/search/searchParamsSlice';
import { useTagStyle } from '../../../features/tags/tagStyleSelector';
import TagCloseIcon from '../tagSelection/TagCloseIcon';

const Tag = ({ name, location = 'default' }) => {
  const dispatch = useDispatch();
  const selectedTags = useSelector(selectSelectedTags);
  const {
    containerClasses,
    contentClasses,
    emoji,
    showClose,
    isClickable,
    style
  } = useTagStyle(name, location);

  const handleClick = () => {
    if (!isClickable) return;
    
    if (selectedTags.includes(name)) {
      const newSelectedTags = selectedTags.filter(tag => tag !== name);
      dispatch(removeSelectedTag(name));
      dispatch(setSelectedTags(newSelectedTags));
    } else {
      const newSelectedTags = [...selectedTags, name];
      dispatch(addSelectedTag(name));
      dispatch(setSelectedTags(newSelectedTags));
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    const newSelectedTags = selectedTags.filter(tag => tag !== name);
    dispatch(removeSelectedTag(name));
    dispatch(setSelectedTags(newSelectedTags));
  };

  return (
    <div
      className={containerClasses}
      style={style}
      onClick={handleClick}
      role={isClickable ? "button" : undefined}
      tabIndex={isClickable ? 0 : undefined}
    >
      <span className="mr-1">{emoji}</span>
      <span className={contentClasses}>{name}</span>
      {showClose && (
        <button
          onClick={handleClose}
          className="ml-1"
          aria-label="Remove tag"
        >
          <TagCloseIcon />
        </button>
      )}
    </div>
  );
};

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.oneOf(['default', 'CardTagList', 'PostTagsList', 'TagList', 'SelectedTagsPanel'])
};

export default Tag;