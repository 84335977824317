import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import TagModal from '../tagSelection/TagModal';
import SortModal from '../sort/SortModal';
import AnimatedBlurOverlay from '../common/AnimatedBlurOverlay';

function ModalManager({ openModal, closeModal, onSortChange }) {
  return (
    <AnimatePresence>
      {openModal && (
        <>
          <AnimatedBlurOverlay 
            onClick={!['loading'].includes(openModal) ? closeModal : undefined} 
          />
          
          {openModal === 'tags' && <TagModal onClose={closeModal} />}
          {openModal === 'sort' && (
            <SortModal 
              onClose={closeModal} 
              onSortChange={onSortChange}
            />
          )}
        </>
      )}
    </AnimatePresence>
  );
}

ModalManager.propTypes = {
  openModal: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default ModalManager;