// Проверка среды

import React from 'react';
import PropTypes from 'prop-types';
import useIsTelegramApp from './useIsTelegramApp';
import AppLoadingText from '../../presentation/components/loading/AppLoadingText';
import NotTelegramPage from '../../presentation/pages/NotTelegramPage';

function TelegramEnvironmentCheck({ children }) {
  const isTelegramApp = useIsTelegramApp();

  if (isTelegramApp === null) {
    return <AppLoadingText text="Проверка среды..." />;
  }

  if (!isTelegramApp) {
    return <NotTelegramPage />;
  }

  return children;
}

TelegramEnvironmentCheck.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TelegramEnvironmentCheck;