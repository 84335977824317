import React from 'react';
import CommentIcon from '../icons/CommentIcon';
import ShareIcon from '../icons/ShareIcon';
import ActionButton from './ActionButton';
import useShareActions from '../../../features/posts/useShareActions';

const ShareButtons = ({ post }) => {
  const { handleDiscussClick, handleShareClick } = useShareActions(post.chat.link, post.title);
  const baseTextClasses = "font-['Inter',_'Helvetica_Neue',_helvetica,_sans-serif] text-psl-main-text-light dark:text-psd-main-text-dark";

  return (
    <div className={`mt-4 flex gap-2 ${baseTextClasses}`}>
      <ActionButton
        icon={ShareIcon}
        label="Отправить"
        onClick={handleShareClick}
        className="w-full flex items-center justify-center px-4 py-2 rounded-md bg-psl-main-accent-light dark:bg-psd-main-accent-dark text-white text-sm font-medium hover:bg-psl-main-accent-light/80 dark:hover:bg-psd-main-accent-dark/80"
      />
      <ActionButton
        icon={CommentIcon}
        label="Обсудить"
        onClick={handleDiscussClick}
        className="w-full flex items-center justify-center px-4 py-2 rounded-md bg-psl-main-accent-light dark:bg-psd-main-accent-dark text-white text-sm font-medium hover:bg-psl-main-accent-light/80 dark:hover:bg-psd-main-accent-dark/80"
      />
    </div>
  );
};

export default ShareButtons;
