import { motion } from 'framer-motion';
import React from 'react';

const pageVariants = {
  initial: {
    opacity: 0,
    y: 10
  },
  animate: {
    opacity: 1,
    y: 0
  },
  exit: {
    opacity: 0,
    y: 10
  }
};

const pageTransition = {
  duration: 0.15,
  ease: 'easeInOut'
};

export const AnimatePagePresence = ({ children }) => (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={pageTransition}
  >
    {children}
  </motion.div>
);