// Главный компонент приложения

import React from 'react';
import TelegramEnvironmentCheck from './features/access/TelegramEnvironmentCheck';
import AppRoutes from './features/navigation/AppRoutes';
import ErrorBoundary from './features/error/ErrorBoundary';
import ErrorMessage from './features/error/ErrorMessage';
import useTelegramTheme from './features/theme/useTelegramTheme';
import useScrollToTop from './features/navigation/useScrollToTop';
import AppBackground from './presentation/components/common/AppBackground';
import useFixedHeight from './features/initialActions/useFixedHeight';

function App() {
  useTelegramTheme();
//  useFixedHeight();
  useScrollToTop(); 

  return (
    <ErrorBoundary>
      <TelegramEnvironmentCheck>
        <AppBackground>
        <AppRoutes />
        <ErrorMessage />
        </AppBackground>
      </TelegramEnvironmentCheck>
    </ErrorBoundary>
  );
}

export default App;