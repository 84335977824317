import React from 'react';

function ArrowsIcon({ className }) {
  return (
    <svg
      viewBox="0 0 16 12"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.03553 0.146447C3.84027 -0.0488155 3.52369 -0.0488155 3.32843 0.146447L0.146447 3.32843C-0.0488155 3.52369 -0.0488155 3.84027 0.146447 4.03553C0.341709 4.2308 0.658291 4.2308 0.853553 4.03553L3.18198 1.70711V11.5C3.18198 11.7761 3.40584 12 3.68198 12C3.95812 12 4.18198 11.7761 4.18198 11.5V1.70711L6.51041 4.03553C6.70567 4.2308 7.02225 4.2308 7.21751 4.03553C7.41278 3.84027 7.41278 3.52369 7.21751 3.32843L4.03553 0.146447ZM12.182 0.5C12.182 0.223858 11.9581 0 11.682 0C11.4058 0 11.182 0.223858 11.182 0.5V10.2929L8.85356 7.96447C8.6583 7.7692 8.34172 7.7692 8.14645 7.96447C7.95119 8.15973 7.95119 8.47631 8.14645 8.67157L11.3284 11.8536C11.5237 12.0488 11.8403 12.0488 12.0355 11.8536L15.2175 8.67157C15.4128 8.47631 15.4128 8.15973 15.2175 7.96447C15.0223 7.7692 14.7057 7.7692 14.5104 7.96447L12.182 10.2929V0.5Z"
      />
    </svg>
  );
}

export default ArrowsIcon;
