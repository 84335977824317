import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFullPost } from '../api/postApi';
import { 
  fetchPostStart,
  fetchPostSuccess,
  fetchPostFailure,
  selectCurrentPost,
  selectPostStatus,
  selectPostError,
  resetPost
} from './postSlice';
import { selectTokens } from '../auth/authSlice';
import { getErrorData } from '../api/apiErrors';

function useFetchPost(articleId) {
  const dispatch = useDispatch();
  const post = useSelector(selectCurrentPost);
  const status = useSelector(selectPostStatus);
  const error = useSelector(selectPostError);
  const { accessToken } = useSelector(selectTokens);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        dispatch(fetchPostStart());
        const response = await getFullPost(accessToken, articleId);
        dispatch(fetchPostSuccess(response));
      } catch (error) {
        dispatch(fetchPostFailure(getErrorData(error)));
      }
    };

    if (articleId) {
      fetchPost();
    }

    return () => {
      dispatch(resetPost());
    };
  }, [articleId, accessToken, dispatch]);

  return {
    post,
    loading: status === 'loading',
    error
  };
}

export default useFetchPost;