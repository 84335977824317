import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../common/Tag';

const CardTagList = ({ tagIds, className = '' }) => {
  if (!tagIds || tagIds.length === 0) return null;

  return (
    <div className={`relative ${className}`}>
      <div className="flex gap-2 overflow-x-auto flex-nowrap scrollbar-hide">
        {tagIds.map(tagName => (
          <Tag
            key={tagName}
            name={tagName}
            className="inline-flex items-center px-2.5 py-1 rounded-lg text-xs 
                      transition-colors duration-200"
            location="CardTagList"
          />
        ))}
      </div>
      <div className="pointer-events-none absolute top-0 right-0 h-full w-5 
                      bg-gradient-to-l from-psl-main-light to-transparent 
                      dark:from-psd-main-dark" />
    </div>
  );
};

CardTagList.propTypes = {
  tagIds: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string
};

export default CardTagList;
