// Ошибка-ошибка, 404. Отображаемая при переходе на неизвестный маршрут

import React from 'react';
import AppModal from '../components/common/AppModal';
import { useNavigate } from 'react-router-dom';
import PatternedBackground from '../components/common/PatternedBackground';

function ErrorPage() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <PatternedBackground>
    <div className="min-h-screen bg-psl-main-light dark:bg-psd-main-dark flex items-center justify-center">
      <AppModal
        emoji="🙈"
        title="Страница не найдена"
        description="Возможно, вы открыли неверную ссылку. Попробуйте перейти на главную страницу."
        buttonText="На главную"
        onButtonClick={handleGoHome}
      />
    </div>
    </PatternedBackground>
  );
}

export default ErrorPage;
