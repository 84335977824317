// Хук для проверки, запущено ли приложение внутри Telegram

import { useState, useEffect } from 'react';

function useIsTelegramApp() {
  const [isTelegramApp, setIsTelegramApp] = useState(null);

  useEffect(() => {
    const checkTelegramApp = () => {
      if (window.Telegram?.WebApp?.initData) {
        setIsTelegramApp(true);
      } else {
        setIsTelegramApp(false);
      }
    };

    checkTelegramApp();
  }, []);

  return isTelegramApp;
}

export default useIsTelegramApp;
