// Общая конфигурация АПИ

export const apiConfig = {
  // Базовый URL для API
  apiBaseURL: 'https://backend.prostodev.space',
  
  // Эндпоинты API
  endpoints: {
    // Эндпоинты аутентификации
    auth: {
      login: '/auth/login',
      refreshToken: '/auth/refresh',
    },
    // Другие эндпоинты
    tags: {
      all: '/tags/all'
    },
    articles: {
      search: '/articles/search',
      get: '/articles/get'
    }
  }
};