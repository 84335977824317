// Управление состоянием аутентификации пользователя

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'loading', // 'loading' | 'authenticated' | 'error'
  hasSubscription: null, // null | true | false
  
  tokens: {
    accessToken: null, // Токены только при наличии подписки
    refreshToken: null 
  },

  error: {
    code: null,    // 400 | 403 | 429 | null 
    message: null  // текст ошибки
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authStart(state) {
      state.status = 'loading';
      state.error = { code: null, message: null };
    },

    authSuccessWithSubscription(state, action) {
      state.status = 'authenticated';
      state.hasSubscription = true;
      state.tokens = {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      };
      state.error = { code: null, message: null };
    },

    authSuccessNoSubscription(state) {
      state.status = 'error';
      state.hasSubscription = false;
      state.tokens = { accessToken: null, refreshToken: null };
      state.error = {
        code: 403,
        message: 'Subscription required'
      };
    },

    authError(state, action) {
      state.status = 'error';
      state.hasSubscription = false;
      state.tokens = { accessToken: null, refreshToken: null };
      state.error = {
        code: action.payload.code,
        message: action.payload.message
      };
    },

    tokenRefreshed(state, action) {
      state.tokens.accessToken = action.payload.accessToken;
    },

    reset(state) {
      return initialState;
    }
  }
});

export const {
  authStart,
  authSuccessWithSubscription,
  authSuccessNoSubscription,
  authError,
  tokenRefreshed,
  reset
} = authSlice.actions;

export const selectAuthStatus = (state) => state.auth.status;
export const selectHasSubscription = (state) => state.auth.hasSubscription;
export const selectTokens = (state) => state.auth.tokens;
export const selectError = (state) => state.auth.error;
export const selectIsAuthenticated = (state) => state.auth.status === 'authenticated';

export default authSlice.reducer;