import React from 'react';

function CloseIcon({ className }) {
  return (
    <svg
      viewBox="0 0 9 9"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.853553 0.146443C0.658291 -0.0488194 0.341708 -0.0488194 0.146446 0.146443C-0.048816 0.341705 -0.048816 0.658287 0.146446 0.85355L3.79289 4.5L0.146446 8.14644C-0.048816 8.34171 -0.048816 8.65829 0.146446 8.85355C0.341708 9.04881 0.658291 9.04881 0.853553 8.85355L4.5 5.2071L8.14645 8.85355C8.34171 9.04881 8.65829 9.04881 8.85355 8.85355C9.04881 8.65829 9.04881 8.34171 8.85355 8.14644L5.20711 4.5L8.85355 0.85355C9.04881 0.658287 9.04881 0.341705 8.85355 0.146443C8.65829 -0.0488194 8.34171 -0.0488194 8.14645 0.146443L4.5 3.79289L0.853553 0.146443Z"
      />
    </svg>
  );
}

export default CloseIcon;
