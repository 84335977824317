import React from 'react';
import PropTypes from 'prop-types';

function SecondaryButton({ text, onClick }) {
  return (
    <button
      onClick={onClick}
      className="w-full bg-psl-secondary-accent-light dark:bg-psd-secondary-accent-dark 
                 text-psl-secondary-text-light dark:text-psd-secondary-text-dark 
                 mt-1.5 py-2 px-3 rounded-lg transition-colors"
    >
      {text}
    </button>
  );
}

SecondaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SecondaryButton;
