// PostContent.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import ScrollProgressBar from './ScrollProgressBar';
import CustomLink from './CustomLink';

const PostContent = ({ post }) => {
  const baseTextClasses =
    "font-['Inter',_'Helvetica_Neue',_helvetica,_sans-serif] text-psl-main-text-light dark:text-psd-main-text-dark";

  const adaptiveTextSize = 'text-[clamp(16px,4vw,18px)] leading-[1.5em]';

  return (
    <article
     style={{ userSelect: 'none' }}
     className={`pt-4 pb-4 ${baseTextClasses}`}>
      <ScrollProgressBar />
      <Markdown
        options={{
          overrides: {
            h2: {
              props: {
                className: `
                  min-w-0 mx-auto mt-[0.625em] mb-[0.20833333em]
                  min-h-[1.5em] font-bold
                  text-[clamp(18px,5vw,21px)] leading-[1.5em]
                  whitespace-pre-wrap
                  ${baseTextClasses}
                `,
              },
            },
            h3: {
              props: {
                className: `
                  min-w-0 mx-auto mt-[0.625em] mb-[0.20833333em]
                  min-h-[1.5em] font-bold
                  text-[clamp(17px,4.5vw,19px)] leading-[1.5em]
                  whitespace-pre-wrap
                  ${baseTextClasses}
                `,
              },
            },
            p: {
              props: {
                className: `
                  min-w-0 mx-auto mb-[0.88235294em]
                  min-h-[1.5em] font-normal
                  ${adaptiveTextSize}
                  whitespace-pre-wrap
                  ${baseTextClasses}
                `,
              },
            },
            a: {
              component: CustomLink,
            },
            strong: {
              props: {
                className: 'font-semibold',
              },
            },
            blockquote: {
              props: {
                className: `
                  min-w-0 mx-auto my-6 pl-6
                  border-l-4 border-psl-main-accent-light dark:border-psd-main-accent-dark
                  italic text-psl-main-text-light/80 dark:text-psd-main-text-dark/80
                `,
              },
            },
            ul: {
              props: {
                className: `
                  min-w-0 mx-auto mb-[0.88235294em]
                  pl-8 list-disc
                  ${baseTextClasses}
                `,
              },
            },
            ol: {
              props: {
                className: `
                  min-w-0 mx-auto mb-[0.88235294em]
                  pl-8 list-decimal
                  ${baseTextClasses}
                `,
              },
            },
            li: {
              props: {
                className: `
                  relative min-h-[1.5em] mb-[0.58823529em] p-0
                  font-normal ${adaptiveTextSize}
                  whitespace-pre-wrap text-left
                  ${baseTextClasses}
                `,
              },
            },
            img: {
              props: {
                className:
                  'relative inline-block h-auto max-w-full align-top border-0 transition-opacity duration-200',
              },
            },
            figure: {
              props: {
                className: 'mx-auto mb-[0.88235294em] text-center',
              },
            },
            pre: {
              props: {
                className:
                  'mx-auto my-4 p-4 bg-psl-secondary-light dark:bg-psd-secondary-dark rounded-md overflow-x-auto',
              },
            },
            code: {
              props: {
                className:
                  'font-mono text-sm px-2 py-1 mx-[0.2em] bg-psl-secondary-light dark:bg-psd-secondary-dark rounded',
              },
            },
          },
        }}
      >
        {post.content}
      </Markdown>
    </article>
  );
};

PostContent.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostContent;
