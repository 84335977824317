// TagsCounter.jsx
import React from 'react';
import PropTypes from 'prop-types';

const TagsCounter = ({ count, onClear }) => {
  return (
    <div className="flex items-center ml-3 pl-3 
                    border-l border-psl-secondary-accent-light dark:border-psd-secondary-accent-dark">
      {/* <span className="text-xs text-psl-secondary-text-light dark:text-psd-secondary-text-dark mr-2">
        {count}
      </span> */}
      <button
        onClick={onClear}
        className="p-1 rounded-md 
                   text-psl-secondary-text-light hover:text-psl-main-text-light 
                   dark:text-psd-secondary-text-dark dark:hover:text-psd-main-text-dark
                   hover:bg-psl-secondary-light dark:hover:bg-psd-secondary-dark
                   transition-colors duration-200"
        aria-label="Сбросить все теги"
      >
        <svg 
          width="14" 
          height="14" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2.5" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        >
          <path d="M18 6L6 18M6 6l12 12" />
        </svg>
      </button>
    </div>
  );
};

TagsCounter.propTypes = {
  count: PropTypes.number.isRequired,
  onClear: PropTypes.func.isRequired
};

export default TagsCounter;