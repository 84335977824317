import React from 'react';

function NoResults() {
  console.log('NoResults component rendered');
  return (
    <div className="flex items-center justify-center h-[calc(100vh-200px)]">
      <p className="text-center text-psl-secondary-text-light dark:text-psd-secondary-text-dark">
        По этому запросу материалов нет.
      </p>
    </div>
  );
}

export default NoResults;