import React from 'react';
import PropTypes from 'prop-types';

const TagCloseIcon = ({ className = "" }) => (
  <div className="w-4 h-4 bg-white rounded-full flex items-center justify-center ml-1">
    <svg
      className="w-2.5 h-2.5 text-psl-main-accent-light dark:text-psd-main-accent-dark"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  </div>
);

TagCloseIcon.propTypes = {
  className: PropTypes.string
};

export default TagCloseIcon;