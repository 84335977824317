import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SmallButton from './SmallButton';
import SearchBar from '../search/SearchBar';
import TagsIcon from '../icons/TagsIcon';
import ArrowsIcon from '../icons/ArrowsIcon';
import ModalManager from './ModalManager';
import SelectedTagsPanel from '../tagSelection/SelectedTagsPanel';
import { selectPostsStatus } from '../../../features/posts/postsSlice';

function SearchHeader() {
  const [openModal, setOpenModal] = useState(null);
  const status = useSelector(selectPostsStatus);

  const handleButtonClick = (modalType) => {
    setOpenModal((prevModal) => (prevModal === modalType ? null : modalType));
  };

  const handleSearchStart = () => {
    setOpenModal('loading');
  };

  const handleSearchEnd = () => {
    if (status === 'succeeded') {
      setTimeout(() => {
        setOpenModal(null);
      }, 100);
    }
  };

  const handleSortChange = (value) => {
    setOpenModal('loading');
  };

  useEffect(() => {
    if (status === 'succeeded' && openModal === 'loading') {
      setTimeout(() => {
        setOpenModal(null);
      }, 100);
    }
  }, [status, openModal]);

  return (
    <>
      <header className="fixed top-0 left-0 right-0 z-50 flex flex-col bg-psl-main-light dark:bg-psd-main-dark shadow-sm">
        <div className="flex items-center p-2">
          <SmallButton
            icon={<TagsIcon className="w-6 h-6 text-psl-main-accent-light dark:text-psd-main-accent-dark" />}
            ariaLabel="Открыть выбор тегов"
            onClick={() => handleButtonClick('tags')}
          />
          <SearchBar 
            onSearchStart={handleSearchStart}
            onSearchEnd={handleSearchEnd}
          />
          <SmallButton
            icon={<ArrowsIcon className="w-6 h-6 text-psl-main-accent-light dark:text-psd-main-accent-dark" />}
            ariaLabel="Открыть выбор сортировки"
            onClick={() => handleButtonClick('sort')}
          />
        </div>
        
        {!openModal && <SelectedTagsPanel />}
      </header>
      <ModalManager 
        openModal={openModal} 
        closeModal={() => setTimeout(() => setOpenModal(null), 100)} // Добавил задержку при закрытии всех модалок
        onSortChange={handleSortChange}
      />
    </>
  );
}

export default SearchHeader;
