// Компонент для отображения списка опций сортировки

import React from 'react';
import PropTypes from 'prop-types';
import SortOptionItem from './SortOptionItem';

const SORT_OPTIONS = [
  { label: 'От новых к старым', value: 'desc' },
  { label: 'От старых к новым', value: 'asc' },
];

const SortOptions = ({ selectedOption, onOptionClick }) => (
  <ul>
    {SORT_OPTIONS.map((option) => (
      <SortOptionItem
        key={option.value}
        label={option.label}
        value={option.value}
        isSelected={selectedOption === option.value}
        onClick={onOptionClick}
      />
    ))}
  </ul>
);

SortOptions.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};

export default SortOptions;

