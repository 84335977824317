// PostCard.jsx
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import PostDescription from './PostDescription';
import CardTagList from './CardTagList';

const PostCard = forwardRef(({ post }, ref) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/post/${post.id}`);
  };

  return (
    <div
      ref={ref}
      onClick={handleClick}
      className="p-5 bg-psl-main-light dark:bg-psd-main-dark 
                 text-psl-main-text-light dark:text-psd-main-text-dark
                 rounded-2xl shadow-sm mb-1 cursor-pointer 
                 hover:shadow-md transition-shadow duration-200"
    >
      <h2 className="text-xl font-bold 
                     text-psl-main-text-light dark:text-psd-main-text-dark 
                     mb-2.5 leading-snug">
        {post.title}
      </h2>
      <PostDescription description={post.description} />
      {post.tags?.length > 0 && (
        <CardTagList tagIds={post.tags} className="mt-3" />
      )}
    </div>
  );
});

PostCard.displayName = 'PostCard';

PostCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    type: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default PostCard;