// Компонент для перехвата и обработки критических ошибок на уровне приложения

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PatternedBackground from '../../presentation/components/common/PatternedBackground'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Логирование ошибки
    console.error("ErrorBoundary caught an error", error, errorInfo);
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <PatternedBackground>
        <div className="min-h-screen flex items-center justify-center text-psl-main-text-light dark:text-psd-main-text-dark">
          <div className="text-center p-6">
            <h2 className="text-2xl font-bold mb-4">Произошла ошибка</h2>
            <div className="mb-6 max-h-40 overflow-y-auto text-sm  text-red-500 p-2 bg-gray-100 border border-red-500 border-[1px] rounded">
              {this.state.errorInfo?.componentStack}
            </div>
            <p className="mb-6">Пожалуйста, перезагрузите приложение и попробуйте снова.</p>
            <button
              onClick={() => window.location.reload()}
              className="bg-psl-light-bg dark:bg-psd-dark-bg px-4 py-2 rounded-md font-semibold bg-psl-main-accent-light hover:bg-psl-text-link text-white dark:bg-psd-main-accent-dark dark:hover:bg-psd-text-link-dark"
            >
              Перезагрузить
            </button>
          </div>
        </div>
        </PatternedBackground>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
