import React from 'react';

function SearchIcon({ className }) {
  return (
    <svg
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      fill="currentColor"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5ZM8.16437 8.87147C7.30243 9.57682 6.20063 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 6.20063 9.57682 7.30243 8.87147 8.16437L12.3536 11.6464C12.5488 11.8417 12.5488 12.1583 12.3536 12.3536C12.1583 12.5488 11.8417 12.5488 11.6464 12.3536L8.16437 8.87147Z" />
    </svg>
  );
}

export default SearchIcon;
