import React from 'react';
import { motion } from 'framer-motion';

function AnimatedBlurOverlay({ onClick }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.13, ease: 'easeInOut', exit: { duration: 0.17 } }} 
      className="fixed inset-0 backdrop-blur-light dark:backdrop-blur-dark bg-overlay-light dark:bg-overlay-dark z-20"
      onClick={onClick}
    />
  );
}

export default AnimatedBlurOverlay;