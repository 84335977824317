import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchPosts } from '../api/postsApi';
import { 
  fetchPostsStart,
  fetchPostsSuccess,
  fetchPostsFailure,
} from './postsSlice';
import { 
  selectSearchQuery,
  selectSelectedTags,
  selectSelectedSortOrder,
} from '../search/searchParamsSlice';
import { selectTokens } from '../auth/authSlice';
import { getErrorData } from '../api/apiErrors';

function useFetchPosts() {
  const dispatch = useDispatch();
  const searchQuery = useSelector(selectSearchQuery);
  const selectedTags = useSelector(selectSelectedTags);
  const sortOrder = useSelector(selectSelectedSortOrder);
  const { accessToken } = useSelector(selectTokens);

  const fetchPosts = useCallback(
    async (page = 1, resetList = false) => {
      try {
        console.log('fetchPosts called:', {
          page,
          resetList,
          searchQuery,
          selectedTags,
          sortOrder,
          currentTime: new Date().toISOString()
        });

        dispatch(fetchPostsStart());

        const searchParams = {
          q: searchQuery,
          page: resetList ? 1 : page,
          per_page: 8,
          sort_order: sortOrder,
          tags: selectedTags.length > 0 ? selectedTags : null,
        };

        console.log('Fetching posts with params:', searchParams);
        
        const response = await searchPosts(accessToken, searchParams);
        
        console.log('Search response received:', {
          hitCount: response.hits.length,
          totalPages: response.totalPages,
          totalHits: response.totalHits,
          responseTime: new Date().toISOString()
        });

        dispatch(
          fetchPostsSuccess({
            hits: response.hits,
            totalPages: response.totalPages,
            totalHits: response.totalHits,
            page: page,
          })
        );
      } catch (error) {
        console.error('Error fetching posts:', {
          error,
          searchQuery,
          page,
          time: new Date().toISOString()
        });
        dispatch(fetchPostsFailure(getErrorData(error)));
      }
    },
    [dispatch, searchQuery, selectedTags, sortOrder, accessToken]
  );

  return { fetchPosts };
}

export default useFetchPosts;