import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PatternedBackground from '../components/common/PatternedBackground';
import SearchHeader from '../components/header/SearchHeader';
import SelectedTagsPanel from '../components/tagSelection/SelectedTagsPanel';
import { selectPosts, selectPostsStatus } from '../../features/posts/postsSlice';
import { selectSelectedTags } from '../../features/tags/tagsSlice';
import useFetchPosts from '../../features/posts/useFetchPosts';
import useInfiniteScroll from '../../features/posts/useInfiniteScroll';
import useSearchParams from '../../features/search/useSearchParams';
import PostsList from '../components/KnowledgeBase/PostsList';

function KnowledgeBasePage() {
  const posts = useSelector(selectPosts);
  const status = useSelector(selectPostsStatus);
  const selectedTags = useSelector(selectSelectedTags);
  const { fetchPosts } = useFetchPosts();
  const { hasMore, isLoading, handleLoadMore } = useInfiniteScroll(fetchPosts);

  useSearchParams(fetchPosts);

  return (
    <PatternedBackground>
      <SearchHeader />
      {selectedTags.length > 0 && <div className="mt-2"></div>}
      <SelectedTagsPanel />
      <PostsList posts={posts} hasMore={hasMore} handleLoadMore={handleLoadMore} />
    </PatternedBackground>
  );
}

export default memo(KnowledgeBasePage);