import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import HeaderModal from '../header/HeaderModal';
import CloseButton from '../common/CloseButton';
import TagList from './TagList';
import Button from '../common/Button';
import SecondaryButton from '../common/SecondaryButton';
import { clearSelectedTags, selectSelectedTags } from '../../../features/tags/tagsSlice';
import { setSelectedTags } from '../../../features/search/searchParamsSlice';

function TagModal({ onClose }) {
  const dispatch = useDispatch();
  const selectedTags = useSelector(selectSelectedTags);

  const handleReset = () => {
    dispatch(clearSelectedTags());
    dispatch(setSelectedTags([]));
  };

  const handleApply = () => {
    dispatch(setSelectedTags(selectedTags));
    onClose();
  };

  return (
    <HeaderModal>
      <div className="relative flex flex-col items-stretch pl-3">
        <div className="absolute top-0 right-0">
          <CloseButton onClick={onClose} />
        </div>
        <div className="mt-2 flex-grow overflow-y-auto pr-3">
          <TagList />
        </div>
        <div className="sticky bottom-0 left-0 right-0 py-2 flex gap-2 bg-psl-main-light dark:bg-psd-main-dark">
          <SecondaryButton 
            text="Сбросить"
            onClick={handleReset}
          />
          <Button 
            text="Применить"
            onClick={handleApply}
          />
        </div>
      </div>
    </HeaderModal>
  );
}

TagModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TagModal;
