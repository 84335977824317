// Отображается при истечении подписки пользователя

import React from 'react';
import AppModal from '../components/common/AppModal';
import FakeKnowledgeBasePage from '../components/common/FakeKnowledgeBasePage';
import BlurOverlay from '../components/common/BlurOverlay';

function ResubscriptionPage() {
  const handleRenew = () => {
    window.location.href = "https://go.prosto.social/#rec808884693";
  };

  const handleDecline = () => {
    // Логика отказа от продления если решим ее делать
  };

  return (
    <div className="relative min-h-screen">
      <FakeKnowledgeBasePage />
      <BlurOverlay />
      <AppModal
        emoji="😔"
        title="Похоже, ваша подписка закончилась"
        description="Ничего страшного, нужно всего лишь оформить её, чтобы получить доступ к «Простому Сообществу»."
        buttonText="Получить доступ"
        onButtonClick={handleRenew}
      />
    </div>
  );
}

export default ResubscriptionPage;


