// src/features/posts/postsSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'idle',
  items: [],
  page: 1,
  perPage: 8,
  totalPages: 0,
  totalHits: 0,
  error: null,
  hasMore: true
};

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    incrementPage: (state) => {
      console.log('incrementPage:', {
        oldPage: state.page,
        newPage: state.page + 1,
        currentItems: state.items.length,
        hasMore: state.hasMore
      });
      state.page += 1;
    },
    
    setPage: (state, action) => {
      console.log('setPage:', {
        oldPage: state.page,
        newPage: action.payload,
        currentItems: state.items.length
      });
      state.page = action.payload;
    },
    
    fetchPostsStart: (state) => {
      console.log('fetchPostsStart:', {
        currentPage: state.page,
        currentItems: state.items.length,
        status: state.status,
        hasMore: state.hasMore
      });
      state.status = 'loading';
      state.error = null;
    },
    
    fetchPostsSuccess: (state, action) => {
      const { hits, totalPages, totalHits, page } = action.payload;
      console.log('fetchPostsSuccess:', {
        receivedPage: page,
        currentStatePage: state.page,
        newItems: hits.length,
        currentItems: state.items.length,
        totalPages,
        willHaveMore: page < totalPages
      });
      
      state.status = 'succeeded';
      state.totalPages = totalPages;
      state.totalHits = totalHits;
      state.hasMore = page < totalPages;
      state.page = page; // Update state.page to the page fetched

      if (page === 1) {
        state.items = hits;
      } else {
        state.items = [...state.items, ...hits];
      }
    },
    
    fetchPostsFailure: (state, action) => {
      console.log('fetchPostsFailure:', {
        error: action.payload,
        currentPage: state.page,
        currentItems: state.items.length
      });
      state.status = 'failed';
      state.error = action.payload;
    },
    
    resetPosts: () => {
      console.log('resetPosts: resetting to initial state');
      return initialState;
    }
  }
});

export const {
  incrementPage,
  setPage,
  fetchPostsStart,
  fetchPostsSuccess,
  fetchPostsFailure,
  resetPosts
} = postsSlice.actions;

export const selectPosts = (state) => state.posts.items;
export const selectPostsStatus = (state) => state.posts.status;
export const selectPostsError = (state) => state.posts.error;
export const selectCurrentPage = (state) => state.posts.page;
export const selectHasMore = (state) => state.posts.hasMore;
export const selectTotalPages = (state) => state.posts.totalPages;

export default postsSlice.reducer;
