import React from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

const VideoContent = ({ post }) => {
  const getYoutubeVideoId = (url) => {
    if (!url) return '';
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|shorts\/|live\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url?.match(regExp);
    return match ? match[1] : '';
  };

  const opts = {
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
      fs: 1,
    },
  };

  const onReady = (event) => {
    console.log('Видео готово к воспроизведению');
  };

  const openInBrowser = () => {
    const videoUrl = post.videoUrl;
    if (videoUrl && window.Telegram?.WebApp?.openLink) {
      window.Telegram.WebApp.openLink(videoUrl, { try_instant_view: false });
    } else {
      console.warn('openLink method is not available.');
    }
  };

  const videoId = getYoutubeVideoId(post.videoUrl);

  return (
    <div className="flex flex-col space-y-3 pb-3 sm:space-y-4 sm:pb-4 md:pb-6">
      <div className="relative w-full max-w-full mx-auto rounded-lg overflow-hidden shadow-md sm:shadow-lg">
        {videoId ? (
          <div className="aspect-video bg-gray-900">
            <YouTube
              videoId={videoId}
              opts={opts}
              onReady={onReady}
              className="w-full h-full"
              iframeClassName="w-full h-full object-cover rounded-lg"
              allowFullScreen
            />
          </div>
        ) : (
          <div className="flex items-center justify-center min-h-[150px] sm:min-h-[200px] bg-gray-100 dark:bg-gray-800 rounded-lg text-gray-600 dark:text-gray-300">
            <p className="text-xs sm:text-sm md:text-base font-medium">Неверная ссылка на видео</p>
          </div>
        )}
      </div>
      <button
        onClick={openInBrowser}
        className="px-4 py-2 mt-3 text-white bg-psl-main-accent-light dark:bg-psd-main-accent-dark rounded-lg font-medium text-sm sm:text-base"
      >
        Открыть во внешнем браузере
      </button>
    </div>
  );
};

VideoContent.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    videoUrl: PropTypes.string
  }).isRequired
};

export default VideoContent;
