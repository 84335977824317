import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import HeaderModal from '../header/HeaderModal';
import CloseButton from '../common/CloseButton';
import SortOptions from './SortOptions';
import { setSelectedSort, selectSelectedSortOrder } from '../../../features/search/searchParamsSlice';

function SortModal({ onClose, onSortChange }) {
  const dispatch = useDispatch();
  const selectedOption = useSelector(selectSelectedSortOrder);

  const handleOptionClick = (optionValue) => {
    dispatch(setSelectedSort(optionValue));
    onSortChange?.(optionValue);
    onClose();
  };

  return (
    <HeaderModal>
      <div className="flex flex-col items-stretch pl-3">
        <div className="flex justify-between items-start">
          <h3 className="text-xl font-extrabold p-1 text-psl-main-text-light dark:text-psd-main-text-dark">
            Сортировка
          </h3>
          <CloseButton onClick={onClose} className="ml-auto" />
        </div>
        <SortOptions
          selectedOption={selectedOption}
          onOptionClick={handleOptionClick}
        />
      </div>
    </HeaderModal>
  );
}

SortModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

export default SortModal;