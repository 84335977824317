// Страница для уведомления о необходимости использовать Telegram

import React from 'react';
import AppModal from '../components/common/AppModal';
import FakeKnowledgeBasePage from '../components/common/FakeKnowledgeBasePage';
import BlurOverlay from '../components/common/BlurOverlay';

function NotTelegramPage() {
  return (
    <div className="relative min-h-screen bg-psl-main-light text-psl-main-text-light">
      <FakeKnowledgeBasePage />
      <BlurOverlay />
      <AppModal
        emoji="🚫"
        title="Приложение доступно только в Telegram"
        description="Пожалуйста, запустите это приложение внутри Telegram."
      />
    </div>
  );
}

export default NotTelegramPage;
