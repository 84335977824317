// Параметры поиска

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchQuery: '',
  selectedTags: [],
  selectedSort: 'desc', 
};

const searchParamsSlice = createSlice({
  name: 'searchParams',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    setSelectedSort: (state, action) => {
      state.selectedSort = action.payload;
    },
  },
});

export const {
  setSearchQuery,
  setSelectedTags,
  setSelectedSort,
} = searchParamsSlice.actions;

export const selectSearchQuery = (state) => state.searchParams.searchQuery;
export const selectSelectedTags = (state) => state.searchParams.selectedTags;
export const selectSelectedSortOrder = (state) => state.searchParams.selectedSort;

export default searchParamsSlice.reducer;

