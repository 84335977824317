// Управление навигацией и перенаправлением 
export const navigateTo = (path) => {
    window.location.href = path;
  };
  
  export const routes = {
    home: '/',
    post: (id) => `/post/${id}`,
    subscription: '/subscription',
    resubscription: '/resubscription',
    reload: '/reload',
    networkError: '/network-error',
    notTelegram: '/not-telegram',
    error: '/error',
  };
  
  