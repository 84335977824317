import React from 'react';

function MarkIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={`${className}`}
      fill="currentColor"
    >
      <path d="M19.98 5.99a1 1 0 0 0-.69.3L9 16.59 5.71 13.3a1 1 0 1 0-1.42 1.42l4 4a1 1 0 0 0 1.42 0l11-11a1 1 0 0 0-.71-1.71z"/>
    </svg>
  );
}

export default MarkIcon;
