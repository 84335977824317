// Компонент для имитации страницы базы знаний

import React from 'react';
import PostCard from '../postCard/PostCard';
import staticPosts from '../../../data/staticPosts';
import BlurOverlay from './BlurOverlay';
import PatternedBackground from '../common/PatternedBackground';

function FakeKnowledgeBasePage() {
  return (
    <PatternedBackground>
      <BlurOverlay />
      <div className="absolute inset-0 z-0 pointer-events-none">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {staticPosts.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </div>
      </div>
    </PatternedBackground>
  );
}

export default FakeKnowledgeBasePage;

