// Компонент для отображения уведомлений об ошибках и предупреждений на уровне приложения

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearError } from './errorSlice';

function ErrorMessage() {
  const errorMessage = useSelector((state) => state.error.message);
  const dispatch = useDispatch();

  if (!errorMessage) return null;

  const handleClose = () => {
    dispatch(clearError());
  };

  return (
    <div
      className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-psl-main-light dark:bg-psd-main-dark px-4 py-2 rounded-lg shadow-lg z-50 ${
        errorMessage ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="flex items-center">
        <span text-psl-main-text-light dark:text-psd-main-text-dark>{errorMessage}</span>
        <button
          className="ml-4 underline"
          onClick={handleClose}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
}

export default ErrorMessage;
