// Модальное окно под заголовком, подстраивается под контент

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function HeaderModal({ children }) {
  return (
    <div className="fixed top-[70px] left-0 right-0 flex justify-center z-50">
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -30 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        className="w-[calc(100%-32px)] max-w-2xl max-h-[calc(100vh-80px)] overflow-y-auto
                   bg-psl-main-light dark:bg-psd-main-dark rounded-xl shadow-lg"
      >
        <div className="p-1">{children}</div>
      </motion.div>
    </div>
  );
}

HeaderModal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderModal;