import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTags } from '../api/tagsApi';
import {
  fetchTagsStart,
  fetchTagsSuccess,
  fetchTagsFailure,
  selectTagsStatus,
  selectTagsError
} from './tagsSlice';
import { getErrorData } from '../api/apiErrors';

function useFetchTags() {
  const dispatch = useDispatch();
  const status = useSelector(selectTagsStatus);
  const error = useSelector(selectTagsError);

  const fetchTags = useCallback(async (accessToken) => {
    try {
      dispatch(fetchTagsStart());
      const response = await getTags(accessToken);
      dispatch(fetchTagsSuccess(response));
    } catch (error) {
      dispatch(fetchTagsFailure(getErrorData(error)));
    }
  }, [dispatch]);

  return {
    fetchTags,
    loading: status === 'loading',
    error
  };
}

export default useFetchTags;