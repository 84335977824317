// Компонент для отображения загрузки с анимацией

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import PatternedBackground from '../common/PatternedBackground';

const AppLoadingText = ({ text }) => {
  return (
    <PatternedBackground>
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 
                    flex flex-col items-center space-y-2 pointer-events-none z-50">
      <span className="text-sm text-psl-secondary-text-light dark:text-psd-secondary-text-dark opacity-75 font-medium">
        {text}
      </span>
      
      {/* Анимация "Три точки" */}
      <div className="flex space-x-1">
        {[0, 1, 2].map((i) => (
          <motion.span
            key={i}
            className="w-2 h-2 bg-psl-main-accent-light dark:bg-psd-main-accent-dark rounded-full"
            initial={{ scale: 1, opacity: 0.7 }}
            animate={{ scale: [1, 1.5, 1], opacity: [0.7, 1, 0.7] }}
            transition={{
              delay: i * 0.2,
              repeat: Infinity,
              duration: 0.6,
              repeatType: 'loop',
            }}
          />
        ))}
      </div>
    </div>
    </PatternedBackground>
  );
};

AppLoadingText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default AppLoadingText;
