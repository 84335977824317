// Загрузка после проверки подписки

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectTokens } from '../auth/authSlice';
import { selectPostsStatus } from '../posts/postsSlice';
import { selectTagsStatus } from '../tags/tagsSlice';
import useFetchPosts from '../posts/useFetchPosts';
import useFetchTags from '../tags/useFetchTags';

function InitialDataLoader({ children }) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { accessToken } = useSelector(selectTokens);
  const postsStatus = useSelector(selectPostsStatus);
  const tagsStatus = useSelector(selectTagsStatus);
  
  const { fetchPosts } = useFetchPosts();
  const { fetchTags } = useFetchTags();

  useEffect(() => {
    if (isAuthenticated) {
      if (postsStatus === 'idle') {
        fetchPosts(1, true);
      }
      if (tagsStatus === 'idle') {
        fetchTags(accessToken);
      }
    }
  }, [isAuthenticated, postsStatus, tagsStatus, fetchPosts, fetchTags, accessToken]);

  return children;
}

export default InitialDataLoader;