import React from 'react';
import { useSelector } from 'react-redux';
import { selectAllTags } from '../../../features/tags/tagsSlice';
import Tag from '../common/Tag';

function TagList() {
  const allTags = useSelector(selectAllTags);

  const groupedTags = allTags.reduce((acc, tag) => {
    if (!acc[tag.type]) {
      acc[tag.type] = [];
    }
    acc[tag.type].push(tag);
    return acc;
  }, {});

  return (
    <div className="space-y-6">
      {Object.entries(groupedTags).map(([type, tags]) => (
        <div key={type} className="space-y-2">
          <h3 className="text-lg font-bold text-psl-main-text-light dark:text-psd-main-text-dark mb-2">
            {type === 'category' ? 'Категории' : 'Форматы'}
          </h3>
          <div className="flex flex-wrap gap-1">
            {tags
              .sort((a, b) => a.sort_position - b.sort_position)
              .map(tag => (
                <Tag
                  key={tag.name}
                  name={tag.name}
                  location="TagList"
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default TagList;