// PostDescription.jsx
import React from 'react';
import PropTypes from 'prop-types';

function PostDescription({ description }) {
  return (
    <p className="text-sm text-gray-600 
                  dark:text-psd-secondary-text-dark 
                  leading-relaxed">
      {description}
    </p>
  );
}

PostDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default PostDescription;