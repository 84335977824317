import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../common/Tag';

const PostTagList = ({ tagIds }) => {
  if (!tagIds || tagIds.length === 0) return null;

  return (
    <div className="relative mt-4">
      <div className="flex gap-2 overflow-x-auto whitespace-nowrap">
        {tagIds.map(tagName => (
          <Tag
            key={tagName}
            name={tagName}
            location="PostTagList"
          />
        ))}
      </div>
      <div className="pointer-events-none absolute top-0 right-0 h-full w-5 bg-gradient-to-l from-psl-main-light to-transparent dark:from-psd-dark-bg"></div>
    </div>
  );
};

PostTagList.propTypes = {
  tagIds: PropTypes.arrayOf(PropTypes.string)
};

export default PostTagList;
