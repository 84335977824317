// CommentIcon.js
import React from 'react';

const CommentIcon = () => (
  <svg
    viewBox="0 0 16 15"
    xmlns="http://www.w3.org/2000/svg"
    className="w-full h-full"
    fill="currentColor"
  >
    <path d="M4.94727 14.5771C4.71029 14.5771 4.52572 14.4974 4.39355 14.3379C4.26595 14.1829 4.20215 13.9733 4.20215 13.709V11.9316H3.87402C3.19499 11.9316 2.62305 11.8109 2.1582 11.5693C1.69336 11.3232 1.34017 10.9678 1.09863 10.5029C0.861654 10.0381 0.743164 9.4707 0.743164 8.80078V3.56445C0.743164 2.89453 0.861654 2.32715 1.09863 1.8623C1.34017 1.39746 1.69336 1.04427 2.1582 0.802734C2.62305 0.556641 3.19499 0.433594 3.87402 0.433594H12.6787C13.3577 0.433594 13.9297 0.556641 14.3945 0.802734C14.8594 1.04427 15.2103 1.39746 15.4473 1.8623C15.6888 2.32715 15.8096 2.89453 15.8096 3.56445V8.80078C15.8096 9.4707 15.6888 10.0381 15.4473 10.5029C15.2103 10.9678 14.8594 11.3232 14.3945 11.5693C13.9297 11.8109 13.3577 11.9316 12.6787 11.9316H8.29004L5.93164 14.0303C5.72656 14.2126 5.55339 14.3493 5.41211 14.4404C5.27083 14.5316 5.11589 14.5771 4.94727 14.5771ZM5.22754 13.3262L7.41504 11.1523C7.54264 11.0202 7.66569 10.9336 7.78418 10.8926C7.90267 10.8516 8.05762 10.8311 8.24902 10.8311H12.6787C13.3669 10.8311 13.8773 10.6602 14.21 10.3184C14.5426 9.97201 14.709 9.46387 14.709 8.79395V3.56445C14.709 2.89909 14.5426 2.39551 14.21 2.05371C13.8773 1.70736 13.3669 1.53418 12.6787 1.53418H3.87402C3.18132 1.53418 2.66862 1.70736 2.33594 2.05371C2.00781 2.39551 1.84375 2.89909 1.84375 3.56445V8.79395C1.84375 9.46387 2.00781 9.97201 2.33594 10.3184C2.66862 10.6602 3.18132 10.8311 3.87402 10.8311H4.71484C4.90169 10.8311 5.03385 10.8698 5.11133 10.9473C5.1888 11.0247 5.22754 11.1569 5.22754 11.3438V13.3262Z" />
  </svg>
);

export default CommentIcon;