import { useSelector } from 'react-redux';
import { selectTagByName, selectIsTagSelected } from '../../features/tags/tagsSlice';

const getBackgroundStyles = (tag, isSelected, currentTheme, location) => {
  if (!tag) return {};
  
  const color = currentTheme === 'dark'
    ? (isSelected ? tag.dark_theme_selected : tag.dark_theme)
    : (isSelected ? tag.light_theme_selected : tag.light_theme);
  
  const style = {
    backgroundColor: color,
  };

  if (location === 'TagList') {
   
    const borderColor = isSelected 
    ? (currentTheme === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)')
    : color;

    style.borderColor = borderColor;
    style.borderWidth = '1px';
    style.borderStyle = 'solid';
  }

  return style;
};

export const useTagStyle = (tagName, location = 'default') => {
  const tag = useSelector(state => selectTagByName(state, tagName));
  const isSelected = useSelector(state => selectIsTagSelected(state, tagName));
  const currentTheme = useSelector(state => state.theme.theme);

  if (!tag) return {
    containerClasses: '',
    contentClasses: '',
    emoji: '',
    showClose: false,
    isClickable: false,
    style: {}
  };

  let containerClasses = 'inline-flex items-center px-2 py-1 rounded-lg text-sm transition-colors duration-200';
  
  if (location === 'TagList') {
    containerClasses += ' border';
  }

  let contentClasses = 'text-psl-main-text-light dark:text-psd-main-text-dark whitespace-nowrap';
  containerClasses += ' sm:px-1 sm:py-0.5 sm:text-xs';

  const style = getBackgroundStyles(tag, isSelected, currentTheme, location);

  switch (location) {
    case 'CardTagList':
    case 'PostTagsList':
      contentClasses += ' cursor-default';
      return {
        containerClasses,
        contentClasses,
        emoji: tag.emoji,
        showClose: false,
        isClickable: false,
        style
      };

    case 'TagList':
      contentClasses += ' cursor-pointer';
      return {
        containerClasses,
        contentClasses,
        emoji: tag.emoji,
        showClose: false,
        isClickable: true,
        style
      };

    case 'SelectedTagsPanel':
      contentClasses += ' cursor-default';
      return {
        containerClasses,
        contentClasses,
        emoji: tag.emoji,
        showClose: true,
        isClickable: false,
        style
      };

    default:
      return {
        containerClasses,
        contentClasses,
        emoji: tag.emoji,
        showClose: false,
        isClickable: false,
        style
      };
  }
};