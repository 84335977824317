import React from 'react';
import { motion, useScroll } from 'framer-motion';

function ScrollProgressBar() {
  const { scrollYProgress } = useScroll();

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-[3px] bg-psl-main-accent-light dark:bg-psd-main-accent-dark z-50"
      style={{ scaleX: scrollYProgress, transformOrigin: '0%' }}
    />
  );
}

export default ScrollProgressBar;