// API аутентификации, запросы к серверу

import axios from 'axios';
import { apiConfig } from '../api/apiConfig';

export const login = async (initData) => {
  const response = await axios.post(
    `${apiConfig.apiBaseURL}${apiConfig.endpoints.auth.login}`, 
    { init_data: initData }
  );
  return response.data;
};

export const refreshAccessToken = async (refreshToken) => {
  const response = await axios.post(
    `${apiConfig.apiBaseURL}${apiConfig.endpoints.auth.refreshToken}`, 
    { refresh_token: refreshToken }
  );
  return response.data;
};