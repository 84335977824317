// Страница для перезагрузки приложения при возникновении ошибки загрузки данных

import React from 'react';
import AppModal from '../components/common/AppModal';
import FakeKnowledgeBasePage from '../components/common/FakeKnowledgeBasePage';
import BlurOverlay from '../components/common/BlurOverlay';

function ReloadPage() {
  const handleReload = () => {
    window.location.reload(); // не сохранённые состояния будут сброшены
  };

  return (
    <div className="relative min-h-screen">
      <FakeKnowledgeBasePage />
      <BlurOverlay />
      <AppModal
        emoji="🤔"
        title="Упс, возникла какая-то ошибка"
        description="Пожалуйста, перезагрузите приложение, чтобы мы попытались её устранить."
        buttonText="Перезагрузить"
        onButtonClick={handleReload}
      />
    </div>
  );
}

export default ReloadPage;

