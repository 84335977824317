// Настройка Redux Store

import { configureStore } from '@reduxjs/toolkit';
import themeReducer from '../features/theme/themeSlice';
import errorReducer from '../features/error/errorSlice';
import authReducer from '../features/auth/authSlice';
import postsReducer from '../features/posts/postsSlice';
import postReducer from '../features/posts/postSlice';
import searchParamsReducer from '../features/search/searchParamsSlice';
import tagsReducer from '../features/tags/tagsSlice';

const store = configureStore({
  reducer: {
    theme: themeReducer,
    error: errorReducer,
    auth: authReducer,
    posts: postsReducer,
    post: postReducer,
    searchParams: searchParamsReducer,
    tags: tagsReducer,
  },
});

export default store;
