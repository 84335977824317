import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingText from '../loading/AppLoadingText';
import EndOfListMessage from './EndOfListMessage';
import NoResults from './NoResults';
import PostCard from '../postCard/PostCard';
import { selectPostsStatus } from '../../../features/posts/postsSlice';

const PostsList = memo(function PostsList({ posts, hasMore, handleLoadMore }) {
  const status = useSelector(selectPostsStatus);

  console.log('PostsList render:', { 
    postsLength: posts?.length, 
    hasMore,
    status,
    shouldShowNoResults: !posts || posts.length === 0 
  });

  // Показываем LoadingText только при первой загрузке
  if (status === 'loading' && (!posts || posts.length === 0)) {
    console.log('Rendering initial LoadingText');
    return <LoadingText text="  " />;
  }

  // Показываем NoResults только когда нет постов и загрузка завершена
  if (status === 'succeeded' && (!posts || posts.length === 0)) {
    console.log('Rendering NoResults');
    return <NoResults />;
  }

  return (
    <InfiniteScroll
      dataLength={posts.length}
      next={handleLoadMore}
      hasMore={hasMore}
      loader={<LoadingText text="  " />}
      endMessage={<EndOfListMessage />}
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 p-4 pt-20"
    >
      {posts.map((post) => {
        console.log('Rendering post:', post.article_id);
        return (
          <PostCard
            key={post.article_id}
            post={{
              id: post.article_id,
              title: post.article_name,
              description: post.article_short_description,
              tags: post.tags,
              type: post.type,
            }}
          />
        );
      })}
    </InfiniteScroll>
  );
});

export default PostsList;