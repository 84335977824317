import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchQuery, selectSearchQuery } from '../../../features/search/searchParamsSlice';
import SearchIcon from '../icons/SearchIcon';
import { debounce } from 'lodash';

function SearchBar({ onSearchStart, onSearchEnd }) {
  const dispatch = useDispatch();
  const savedQuery = useSelector(selectSearchQuery);
  const [inputValue, setInputValue] = useState(savedQuery || '');

  const debouncedDispatch = useCallback(
    debounce((value) => {
      onSearchStart?.(); // Переместили сюда, чтобы блюр появлялся после debounce
      dispatch(setSearchQuery(value));
      // Добавили задержку перед скрытием блюра
      setTimeout(() => {
        onSearchEnd?.();
      }, 100);
    }, 300),
    [dispatch, onSearchStart, onSearchEnd]
  );

  useEffect(() => {
    setInputValue(savedQuery || '');
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedDispatch(value);
  };

  return (
    <div className="relative flex-grow mx-2">
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Поиск"
        className="w-full py-2 pl-10 pr-4 rounded-xl bg-psl-secondary-light dark:bg-psd-secondary-dark text-psl-main-text-light dark:text-psd-main-text-dark placeholder-psl-secondary-text-light dark:placeholder-psd-secondary-text-dark focus:outline-none"
      />
      <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
        <SearchIcon className="w-5 h-5 text-psl-secondary-text-light dark:text-psd-secondary-text-dark" />
      </div>
    </div>
  );
}

export default SearchBar;