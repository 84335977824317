// Отображается при отсутствии интернет-соединения

import React from 'react';
import AppModal from '../components/common/AppModal';
import PatternedBackground from '../components/common/PatternedBackground';

function NetworkErrorPage() {
  const handleRetry = () => {
    if (navigator.onLine) {
      window.location.replace('/');
    }
  };

  return (
    <PatternedBackground>
      <div className="min-h-screen flex items-center justify-center">
        <AppModal
          emoji="📡"
          title="Нет подключения к интернету"
          description="Проверьте соединение и попробуйте снова."
          buttonText="Повторить попытку"
          onButtonClick={handleRetry}
        />
      </div>
    </PatternedBackground>
  );
}

export default NetworkErrorPage;

