import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  allTagsData: [],
  selectedTags: [],
  status: 'idle',
  error: null
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    fetchTagsStart: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    fetchTagsSuccess: (state, action) => {
      state.status = 'succeeded';
      state.allTagsData = action.payload;
      state.error = null;
    },
    fetchTagsFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    addSelectedTag: (state, action) => {
      const tagName = action.payload;
      if (!state.selectedTags.includes(tagName)) {
        state.selectedTags.push(tagName);
      }
    },
    removeSelectedTag: (state, action) => {
      const tagName = action.payload;
      state.selectedTags = state.selectedTags.filter(tag => tag !== tagName);
    },
    clearSelectedTags: (state) => {
      state.selectedTags = [];
    }
  }
});

export const {
  fetchTagsStart,
  fetchTagsSuccess,
  fetchTagsFailure,
  addSelectedTag,
  removeSelectedTag,
  clearSelectedTags
} = tagsSlice.actions;

const selectTagsState = state => state.tags;
const selectAllTagsData = state => state.tags.allTagsData;
const selectTagsSelectedTags = state => state.tags.selectedTags;

export const selectAllTags = createSelector(
  [selectAllTagsData],
  (tags) => tags
);

export const selectSelectedTags = createSelector(
  [selectTagsSelectedTags],
  (selectedTags) => selectedTags
);

export const selectTagsStatus = createSelector(
  [selectTagsState],
  (tags) => tags.status
);

export const selectTagsError = createSelector(
  [selectTagsState],
  (tags) => tags.error
);

export const selectTagByName = createSelector(
  [selectAllTagsData, (_, tagName) => tagName],
  (tags, tagName) => tags.find(tag => tag.name === tagName)
);

export const selectIsTagSelected = createSelector(
  [selectTagsSelectedTags, (_, tagName) => tagName],
  (selectedTags, tagName) => selectedTags.includes(tagName)
);

export default tagsSlice.reducer;