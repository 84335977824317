import React from 'react';

function TagsIcon({ className }) {
  return (
    <svg
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6066 1.41422L14.2634 3.07107C14.451 3.2586 14.5563 3.51296 14.5563 3.77817V6.36396C14.5563 6.62918 14.451 6.88354 14.2634 7.07107L7.77817 13.5563C7.38765 13.9469 6.75448 13.9469 6.36396 13.5563L2.12132 9.31371C1.73079 8.92318 1.73079 8.29002 2.12132 7.89949L8.6066 1.41422C8.79413 1.22668 9.04849 1.12132 9.3137 1.12132H11.8995C12.1647 1.12132 12.4191 1.22668 12.6066 1.41422ZM11.8995 0.12132C12.4299 0.121315 12.9386 0.332036 13.3137 0.707109L14.9706 2.36396C15.3456 2.73903 15.5564 3.24774 15.5563 3.77817V6.36396C15.5563 6.8944 15.3456 7.4031 14.9706 7.77818L8.48528 14.2635C7.70423 15.0445 6.4379 15.0445 5.65685 14.2635L1.41421 10.0208C0.633162 9.23977 0.633162 7.97344 1.41421 7.19239L7.89949 0.707109C8.27457 0.33203 8.78327 0.121326 9.3137 0.12132H11.8995ZM10.9602 4.71751C11.253 5.01041 11.7279 5.01041 12.0208 4.71751C12.3137 4.42462 12.3137 3.94975 12.0208 3.65685C11.7279 3.36396 11.253 3.36396 10.9602 3.65685C10.6673 3.94975 10.6673 4.42462 10.9602 4.71751Z"
      />
    </svg>
  );
}

export default TagsIcon;