// src/features/posts/useInfiniteScroll.js

import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectHasMore, 
  selectPostsStatus,
  selectCurrentPage,
  incrementPage 
} from './postsSlice';

function useInfiniteScroll(fetchPosts) {
  const dispatch = useDispatch();
  const hasMore = useSelector(selectHasMore);
  const status = useSelector(selectPostsStatus);
  const currentPage = useSelector(selectCurrentPage);
  const isLoading = status === 'loading';
  const loadingRef = useRef(false);

  const handleLoadMore = useCallback(() => {
    console.log('handleLoadMore called:', {
      currentPage,
      hasMore,
      isLoading,
      status,
      loadingRef: loadingRef.current
    });

    if (!loadingRef.current && !isLoading && hasMore) {
      console.log('Loading more posts...');
      loadingRef.current = true;
      const nextPage = currentPage + 1;
      fetchPosts(nextPage).finally(() => {
        // dispatch(incrementPage()); возможная ошибка
        loadingRef.current = false;
      });
    } else {
      console.log('Skipping load more:', {
        loadingRef: loadingRef.current,
        isLoading,
        hasMore
      });
    }
  }, [isLoading, hasMore, currentPage, dispatch, fetchPosts]);

  return {
    hasMore,
    isLoading: isLoading || loadingRef.current,
    handleLoadMore
  };
}

export default useInfiniteScroll;
