import React from 'react';

const ActionButton = ({ icon: Icon, label, onClick }) => {
 return (
   <button
     onClick={onClick}
     className="w-full flex items-center justify-center px-6 py-2 
                rounded-xl border border-psl-main-accent-light text-psl-main-accent-light 
                bg-psl-light-bg hover:bg-overlay-light 
                dark:bg-psd-dark-bg dark:border-psd-main-accent-dark dark:text-psd-main-accent-dark 
                dark:hover:bg-overlay-dark 
                transition-colors"
   >
     <span className="w-4 h-4 mr-2">
       <Icon className="w-full h-full" />
     </span>
     <span className="text-sm font-medium">{label}</span>
   </button>
 );
};

export default ActionButton;
