import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchPost from '../../features/posts/useFetchPost';
import useTelegramNavigation from '../../features/telegramApi/useTelegramNavigation';
import AppLoadingText from '../components/loading/AppLoadingText';
import ErrorMessage from '../../features/error/ErrorMessage';
import PostContent from '../components/postPage/PostContent';
import VideoContent from '../components/postPage/VideoContent';
import PostTagList from '../components/postPage/PostTagList';
import ShareButtons from '../components/postPage/ShareButtons';

const PostPage = () => {
  const { id } = useParams();
  const { post, loading, error } = useFetchPost(id);
  const [showButtons, setShowButtons] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);

  useTelegramNavigation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > lastScrollPos && showButtons) {
        setShowButtons(false);
      } else if (currentScrollPos < lastScrollPos && !showButtons) {
        setShowButtons(true);
      }
      setLastScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollPos, showButtons]);

  if (loading) {
    return <AppLoadingText text="Загрузка публикации..." />;
  }

  if (error) {
    return <ErrorMessage text={`Ошибка: ${error.message}`} />;
  }

  if (!post) {
    return <ErrorMessage text="Публикация не найдена" />;
  }

  const baseTextClasses =
    "font-['Inter',_'Helvetica_Neue',_helvetica,_sans-serif] text-psl-main-text-light dark:text-psd-main-text-dark";

  return (
    <div className="min-h-screen bg-psl-light-bg dark:bg-psd-dark-bg pt-4 pb-8">
      <div className="max-w-[1000px] mx-auto px-4">
        {post.tags?.length > 0 && (
          <div className="mb-6">
            <PostTagList tagIds={post.tags} />
          </div>
        )}
  
        <h1
          className={`block min-w-0 mx-auto mb-[.78947368em] font-extrabold text-left break-words text-[24px] leading-[1.15em] ${baseTextClasses}`}
        >
          {post.title}
        </h1>
  
        {post.type === 'video' ? (
          <VideoContent post={post} />
        ) : (
          <PostContent post={post} />
        )}
      </div>
      <div className="max-w-[1000px] mx-auto px-4">
        <ShareButtons post={post} />
      </div>
    </div>
  );
    
};

export default PostPage;
