// Отвечает за всю логику обновления токена

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { refreshAccessToken } from '../api/authApi';
import { 
  tokenRefreshed, 
  authError, 
  authSuccessWithSubscription 
} from './authSlice';

export function useTokenRefresh() {
  const dispatch = useDispatch();

  const handleRefreshSuccess = useCallback((data) => {
    if (data.access_token && data.refresh_token) {
      dispatch(authSuccessWithSubscription({
        accessToken: data.access_token,
        refreshToken: data.refresh_token
      }));
    } else {
      dispatch(tokenRefreshed({ 
        accessToken: data.access_token 
      }));
    }
  }, [dispatch]);

  const handleRefreshError = useCallback((error) => {
    const status = error.response?.status;
    
    switch (status) {
      case 401:
        dispatch(authError({
          code: 401,
          message: 'Invalid refresh token'
        }));
        break;
      case 429:
        dispatch(authError({
          code: 429,
          message: 'Too many refresh token requests'
        }));
        break;
      default:
        dispatch(authError({
          code: status || 500,
          message: error.message
        }));
    }
  }, [dispatch]);

  const refreshToken = useCallback(async (refreshTokenValue) => {
    try {
      const data = await refreshAccessToken(refreshTokenValue);
      handleRefreshSuccess(data);
      return true;
    } catch (error) {
      handleRefreshError(error);
      return false;
    }
  }, [handleRefreshSuccess, handleRefreshError]);

  return { refreshToken };
}