
export const ERROR_CODES = {
    INVALID_INIT_DATA: 400,    // Неверные данные инициализации
    INVALID_TOKEN: 401,        // Невалидный токен
    NO_SUBSCRIPTION: 403,      // Нет подписки
    NOT_FOUND: 404,           // Ресурс не найден
    TOO_MANY_REQUESTS: 429,   // Превышен лимит запросов
    SERVER_ERROR: 500         // Ошибка сервера
  };
  
  export const getErrorData = (error) => ({
    code: error.response?.status || ERROR_CODES.SERVER_ERROR,
    message: error.response?.data?.message || error.message
  });