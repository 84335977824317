// SelectedTagsPanel.jsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tag from '../common/Tag';
import { selectSelectedTags, clearSelectedTags } from '../../../features/tags/tagsSlice';
import { setSelectedTags } from '../../../features/search/searchParamsSlice';
import TagsCounter from './TagsCounter';

const SelectedTagsPanel = () => {
  const dispatch = useDispatch();
  const selectedTags = useSelector(selectSelectedTags);

  if (selectedTags.length === 0) return null;

  const handleClearAll = () => {
    dispatch(clearSelectedTags());
    dispatch(setSelectedTags([])); 
  };

  return (
    <div className="sticky top-0 z-10 w-full 
                    bg-psl-main-light/90 dark:bg-psd-main-dark/90 
                    backdrop-blur-sm
                    border-b border-psl-secondary-accent-light dark:border-psd-secondary-accent-dark">
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex-1 overflow-x-auto scrollbar-hide">
          <div className="flex gap-1.5 whitespace-nowrap">
            {selectedTags.map(tagName => (
              <Tag
                key={tagName}
                name={tagName}
                location="SelectedTagsPanel"
              />
            ))}
          </div>
        </div>
        
        <TagsCounter 
          count={selectedTags.length} 
          onClear={handleClearAll} 
        />
      </div>
    </div>
  );
};

export default SelectedTagsPanel;