// Кнопка закрытия 

import React from 'react';
import CloseIcon from '../icons/CloseIcon';

const CloseButton = ({ onClick }) => {
    return (
        <button
            onClick={onClick}
            className="p-2 rounded-lg"
            aria-label="Close"
        >
            <CloseIcon className="w-4 h-4 text-psl-secondary-text-light dark:text-psd-secondary-text-dark" />
        </button>
    );
};

export default CloseButton;




