// Блюр

import React from 'react';

function BlurOverlay() {
  return (
    <div
      className="fixed inset-0 backdrop-blur-light dark:backdrop-blur-dark bg-overlay-light dark:bg-overlay-dark z-20"
    ></div>
  );
}

export default BlurOverlay;