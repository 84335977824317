// ShareIcon.js
import React from 'react';

const ShareIcon = () => (
  <svg
    viewBox="0 0 14 12"
    xmlns="http://www.w3.org/2000/svg"
    className="w-full h-full"
    fill="currentColor"
  >
    <path d="M13.9857 1.09547L12.2537 11.2285C12.1396 11.8964 11.3934 12.2192 10.8505 11.8358L6.22379 8.56786C5.94017 8.36753 5.90645 7.94955 6.15408 7.70376L10.3901 3.49913C10.5131 3.37705 10.4728 3.16493 10.3143 3.09959C10.2426 3.07006 10.1614 3.07863 10.097 3.12252L4.46209 6.96471C4.02488 7.26283 3.48365 7.35102 2.97844 7.20648L0.397747 6.46808C-0.0989966 6.32595 -0.141922 5.61764 0.333806 5.41299L12.7455 0.0735809C13.4057 -0.210435 14.1097 0.369666 13.9857 1.09547Z" />
  </svg>
);

export default ShareIcon;