import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function useTelegramNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const tg = window.Telegram?.WebApp;

    if (tg) {
      tg.ready();
      tg.expand();

      tg.BackButton.show();
      tg.BackButton.onClick(() => {
        tg.BackButton.hide();

        if (location.state?.from) {
          navigate(location.state.from, {
            replace: true
          });
        } else {
          navigate('/', { replace: true });
        }
      });

      return () => {
        tg.BackButton.offClick();
        tg.BackButton.hide();
      };
    }
  }, [navigate, location]);
}

export default useTelegramNavigation;