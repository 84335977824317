import { useCallback } from 'react';

const useShareActions = (chatLink, title) => {
  const handleDiscussClick = useCallback(() => {
    if (chatLink) {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.openTelegramLink(chatLink);
      } else {
        window.open(chatLink, '_blank', 'noopener,noreferrer');
      }
    } else {
      console.warn("Ссылка на чат не задана");
    }
  }, [chatLink]);

  const handleShareClick = useCallback(() => {
    const url = "https://t.me/ProstoFrontendBot?startapp";
    const text = title || document.title;

    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;

    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openTelegramLink(shareUrl);
    } else {
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    }
  }, [title]);

  return { handleDiscussClick, handleShareClick };
};

export default useShareActions;
