// Компонент для отображения сообщения о завершении списка

import React from 'react';

function EndOfListMessage() {
  return (
    <p className="text-center text-psl-secondary-text-light dark:text-psd-secondary-text-dark">
      Больше материалов по этому запросу нет
    </p>
  );
}

export default EndOfListMessage;
