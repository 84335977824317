// Получение текущей темы в Telegram, подписка на изменение

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from './themeSlice';

function useTelegramTheme() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const validThemes = ['light', 'dark'];

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      const tg = window.Telegram.WebApp;
      const currentTheme = validThemes.includes(tg.colorScheme) ? tg.colorScheme : 'light';
      dispatch(setTheme(currentTheme));

      tg.onEvent('themeChanged', () => {
        const newTheme = validThemes.includes(tg.colorScheme) ? tg.colorScheme : 'light';
        dispatch(setTheme(newTheme));
      });
    } else {
      // Тема по умолчанию светлая
      dispatch(setTheme('light'));
    }
  }, [dispatch]);

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);
}

export default useTelegramTheme;

