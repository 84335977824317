// Компонент для управления доступом к маршрутам в зависимости от среды и статуса аутентификации.

import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
  selectAuthStatus, 
  selectHasSubscription, 
  selectError 
} from '../auth/authSlice';
import { ERROR_CODES } from '../api/apiErrors';
import useAuth from '../auth/useAuth';
import { routes } from '../navigation/navigation';
import AppLoadingText from '../../presentation/components/loading/AppLoadingText';
import InitialDataLoader from '../initialActions/InitialDataLoader';

function RouteAccessControl() {
  const location = useLocation();
  const status = useSelector(selectAuthStatus);
  const hasSubscription = useSelector(selectHasSubscription);
  const error = useSelector(selectError);
  const { performLogin } = useAuth();

  useEffect(() => {
    if (status === 'loading') {
      performLogin();
    }
  }, [status, performLogin]);

  if (status === 'loading') {
    return <AppLoadingText text="Аутентификация..." />;
  }

  // Проверяем ошибки
  if (status === 'error') {
    switch (error.code) {
      case ERROR_CODES.INVALID_INIT_DATA:
        return <Navigate to={routes.error} replace />;
      case ERROR_CODES.NO_SUBSCRIPTION:
        return <Navigate to={routes.subscription} replace />;
      case ERROR_CODES.TOO_MANY_REQUESTS:
        return <Navigate to={routes.reload} replace />;
      default:
        return <Navigate to={routes.error} replace />;
    }
  }

  // Если аутентификация прошла успешно и есть подписка,
  return (
    <InitialDataLoader>
      <Outlet />
    </InitialDataLoader>
  );
}

export default RouteAccessControl;