import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import RouteAccessControl from '../access/RouteAccessControl';
import KnowledgeBasePage from '../../presentation/pages/KnowledgeBasePage';
import SubscriptionPage from '../../presentation/pages/SubscriptionPage';
import ResubscriptionPage from '../../presentation/pages/ResubscriptionPage';
import ReloadPage from '../../presentation/pages/ReloadPage';
import NetworkErrorPage from '../../presentation/pages/NetworkErrorPage';
import ErrorPage from '../../presentation/pages/ErrorPage';
import PostPage from '../../presentation/pages/PostPage';
import NotTelegramPage from '../../presentation/pages/NotTelegramPage';
import { routes } from './navigation';
import { AnimatePagePresence } from './AnimatePagePresence';

function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {/* Публичные маршруты без анимации */}
        <Route path={routes.subscription} element={<SubscriptionPage />} />
        <Route path={routes.resubscription} element={<ResubscriptionPage />} />
        <Route path={routes.reload} element={<ReloadPage />} />
        <Route path={routes.networkError} element={<NetworkErrorPage />} />
        <Route path={routes.error} element={<ErrorPage />} />
        <Route path={routes.notTelegram} element={<NotTelegramPage />} />

        {/* Защищенные маршруты с анимацией */}
        <Route element={<RouteAccessControl />}>
          <Route 
            path={routes.home} 
            element={
                <KnowledgeBasePage />
            }
          />
          <Route 
            path={routes.post(':id')} 
            element={
              <AnimatePagePresence>
                <PostPage />
              </AnimatePagePresence>
            }
          />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default AppRoutes;