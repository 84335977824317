// Основное модальное окно для приложения

import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

function AppModal({ emoji, title, description, benefits, buttonText, onButtonClick }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-overlay-light dark:bg-overlay-dark p-3.5 sm:p-0">
      <div className="relative w-full sm:max-w-md bg-psl-main-light dark:bg-psd-main-dark text-psl-main-text-light dark:text-psd-main-text-dark
                      rounded-2xl shadow-lg p-4 mx-0.75 sm:mx-0">
        <div className="flex items-start mb-1">
          <div className="w-5/6">
            <h4 className="text-xl font-extrabold">{title}</h4>
          </div>
          <div className="w-1/6 text-2xl flex justify-end">
            <span>{emoji}</span>
          </div>
        </div>

        <p className="text-psl-main-text-light dark:text-psd-main-text-dark mb-1">{description}</p>

        {benefits && (
        <ul className="list-disc list-inside mb-4 space-y-0.5">
          {benefits.map((benefit, index) => (
    <li key={index} className="pl-2 indent-2">{benefit}</li>
  ))}
        </ul>
        )}
        <div className="mx-1">
          {buttonText && onButtonClick && (
            <Button text={buttonText} onClick={onButtonClick} />
          )}
        </div>
      </div>
    </div>
  );
}

AppModal.propTypes = {
  emoji: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  benefits: PropTypes.arrayOf(PropTypes.string),
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default AppModal;
