import axios from 'axios';
import { apiConfig } from './apiConfig';

export const getTags = async (accessToken) => {
  const response = await axios.post(
    `${apiConfig.apiBaseURL}${apiConfig.endpoints.tags.all}`,
    {}, // должно быть пустое тело запроса
    {
      headers: {
        auth: accessToken
      }
    }
  );
  return response.data;
};