// Фон с узором

import React from 'react';
import PropTypes from 'prop-types';

function PatternedBackground({ children }) {
  return (
    <div className="relative bg-psl-light-pageImage dark:bg-psd-dark-pageImage min-h-screen bg-fixed">
      {children}
    </div>
  );
}

PatternedBackground.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PatternedBackground;
