import axios from 'axios';
import { apiConfig } from './apiConfig';

export const searchPosts = async (accessToken, searchParams) => {
  const response = await axios.post(
    `${apiConfig.apiBaseURL}${apiConfig.endpoints.articles.search}`,
    {
      q: searchParams.q || '',
      page: searchParams.page || 1,
      per_page: searchParams.per_page || 8,
      sort_order: searchParams.sort_order || 'desc',
      tags: searchParams.tags || null
    },
    {
      headers: {
        auth: accessToken
      }
    }
  );
  return response.data;
};