// CustomLink.jsx
import React, { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const CustomLink = ({ children, href, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isInternalRoute = href?.startsWith('/') && !href.startsWith('//');

  const handleClick = useMemo(() => {
    if (!isInternalRoute) {
      return (e) => {
        if (window.Telegram?.WebApp?.openLink) {
          e.preventDefault();
          window.Telegram.WebApp.openLink(href, { try_instant_view: true });
        }
      };
    }

    return (e) => {
      e.preventDefault();
      navigate(href, {
        state: { from: location.pathname },
      });
    };
  }, [href, isInternalRoute, navigate, location.pathname]);

  const content = children?.toString() || '';

  const isUrl = (text) => {
    try {
      new URL(text);
      return true;
    } catch {
      return /^(www\.)|(\.[a-z]{2,})/i.test(text);
    }
  };

  const hasLongWords = (text) => {
    return text.split(' ').some((word) => word.length > 25);
  };

  const needsBreakAll = !isInternalRoute && (isUrl(content) || hasLongWords(content));

  const linkClasses = `
    border-0 
    text-psl-text-link dark:text-psd-text-link-dark
    underline decoration-psl-text-link/30 dark:decoration-psd-text-link-dark/30
    decoration-1 underline-offset-[0.15em]
    transition-all duration-400 cursor-pointer
    hover:text-psl-text-link-pressed dark:hover:text-psd-text-link-pressed-dark
    ${needsBreakAll ? 'break-all' : 'break-normal'}
    ${'text-[clamp(16px,4vw,18px)] leading-[1.5em]'}
  `.trim();

  return (
    <a
      {...props}
      href={href}
      onClick={handleClick}
      className={linkClasses}
    >
      {children}
    </a>
  );
};

export default CustomLink;
