import React from 'react';

export const AppBackground = ({ children }) => {
  return (
    <div className="min-h-screen bg-psl-light-bg dark:bg-psd-dark-bg">
      {children}
    </div>
  );
};

export default AppBackground;