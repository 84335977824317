// Основной хук для управления аутентификацией

// useAuth.js
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../api/authApi';
import { 
  authStart, 
  authSuccessWithSubscription,
  authSuccessNoSubscription,
  authError 
} from './authSlice';
import useTokenExpirationTimer from './useTokenExpirationTimer';

function useAuth() {
  const dispatch = useDispatch();
  useTokenExpirationTimer(null);

  const performLogin = useCallback(async () => {
    dispatch(authStart());
    const initData = window.Telegram.WebApp.initData;

    try {
      const data = await login(initData);
      
      // Подписка есть
      if (data.access_token && data.refresh_token) {
        dispatch(authSuccessWithSubscription({
          accessToken: data.access_token,
          refreshToken: data.refresh_token
        }));
      } else {
        // Подписки нет
        dispatch(authSuccessNoSubscription());
      }
    } catch (error) {
      const status = error.response?.status;
      
      dispatch(authError({
        code: status,
        message: error.message
      }));
    }
  }, [dispatch]);

  return { performLogin };
}

export default useAuth;