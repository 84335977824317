// Базовая кнопка приложения

import React from 'react';
import PropTypes from 'prop-types';

function Button({ text, onClick }) {
  return (
    <button
      onClick={onClick}
      className="w-full bg-psl-main-accent-light hover:bg-psl-text-link dark:bg-psd-main-accent-dark
                 dark:hover:bg-psd-text-link-dark text-white mt-1.5 py-2 px-3 rounded-lg transition-colors"
    >
      {text}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Button;
